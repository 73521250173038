import * as Excel from 'exceljs';

import { CSConstants } from '../csconstants.enum';
import { CSPart } from './cspart.model';

export class CSElement {

  public parts: CSPart[] = [];

  public constructor(eleRow: Excel.Row) {
    let desiredCol: number = CSConstants.FIRST_ELEMENT_COL;

    eleRow.eachCell((curCell: Excel.Cell) => {
      const cellIndex: number = curCell.fullAddress.col as unknown as number;
      while (cellIndex > desiredCol && desiredCol <= CSConstants.MAX_ELEMENT_COL) {
        this.parts.push(new CSPart());
        desiredCol++;
      }

      if (cellIndex > CSConstants.ITEM_TITLE_COL && cellIndex <= CSConstants.MAX_ELEMENT_COL) {
        try {
          this.parts.push(new CSPart(curCell));
        } catch (error) {
          console.error(error);
        }
        desiredCol++;
      }
    });
  }

  public isBlank(): boolean {
    return !this.parts.some(part => !part.blank);
  }

}
