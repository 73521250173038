import { Component, HostBinding, Input } from '@angular/core';

import { CSElement } from '../models/coversheet/cselement.model';
import { CSPart } from '../models/coversheet/cspart.model';
import { LineDisplay } from '../models/lineDisplay.enum';

@Component({
  selector: 'csw-element',
  templateUrl: './cs-element.component.html',
  styleUrls: ['./cs-element.component.scss'],
})
export class CsElementComponent {
  parts: CSPart[] = [];

  @HostBinding('class.chaos')
  isChaos: boolean;

  @Input() xmlTheme: Document;
  @Input()
  set element(ele: CSElement) {
    this.parts = ele.parts;
  }

  @Input()
  set displayMode(display: LineDisplay) {
    this.isChaos = display === LineDisplay.CHAOS;
  }
}
