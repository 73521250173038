<csw-header [lastUpdated]="coversheet?.timestamp" [locCD]="curLoc" [displayMode]="coversheet?.mode"></csw-header>
<csw-admin [locCD]="curLoc" (csLoaded)="coversheetLoaded($event)" (csPublish)="publishCoversheet($event)"></csw-admin>
<mat-progress-bar mode="query" *ngIf="coversheet === undefined" color="primary"></mat-progress-bar>
<ng-container *ngIf="coversheet">
  <csw-coversheet [coversheet]="coversheet" [location]="curLoc" [xmlTheme]="xmlTheme"></csw-coversheet>
</ng-container>
<div id="blankCoversheet" *ngIf="coversheet === null">
  <h1>No Cover Sheet for today</h1>
</div>
<csw-footer></csw-footer>
