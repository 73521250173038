import dayjs from 'dayjs';
import { timer } from 'rxjs';
import {
  AfterViewInit,
  Component,
  HostBinding,
  Inject,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DisplayMode } from '../models/displayMode.enum';
import { CmsService } from '../services/cms.service';

@Component({
  selector: 'csw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  public curTime: dayjs.Dayjs;
  public displays: any = DisplayMode;
  logoUrl: string;

  @HostBinding('class')
  public classes: string = null;

  @Input()
  public lastUpdated: dayjs.Dayjs;
  public _displayMode: DisplayMode;

  page: number;

  @Input() locCD: string;

  constructor(private route: ActivatedRoute, private cmsService: CmsService) {}

  ngAfterViewInit() {
    this.cmsService
      .getHeaderLogoUrl(this.locCD.toUpperCase())
      .subscribe((logoUrl) => {
        this.logoUrl = logoUrl;
      });

    this.route.queryParams.subscribe((params) => {
      this.page = params['p'];
    });
    timer(0, 500).subscribe(() => {
      this.curTime = dayjs();
    });
  }

  @Input()
  set displayMode(display: DisplayMode) {
    if (this.page) {
      this._displayMode = display;
    } else {
      this._displayMode = DisplayMode.NORMAL;
    }
    this.classes = this._displayMode === DisplayMode.CHAOS ? 'chaos' : null;
  }
}
