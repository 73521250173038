import * as Excel from 'exceljs';

import { CSConstants } from '../csconstants.enum';
import { CSElement } from './cselement.model';
import { CSPart } from './cspart.model';

export class CSLineItem {

  public title: CSPart;

  public elements: CSElement[] = [];

  public constructor(itemRows: Excel.Row[]) {
    itemRows.forEach(curRow => {
      if (!this.title && curRow.getCell(CSConstants.ITEM_TITLE_COL)) {
        this.title = new CSPart(curRow.getCell(CSConstants.ITEM_TITLE_COL));
      }

      const addMe: CSElement = new CSElement(curRow);

      if (!addMe.isBlank()) {
        this.elements.push(addMe);
      }
    });
  }

}
