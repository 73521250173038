import { CSBlock } from 'src/app/shared/models/coversheet/csblock.model';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'csw-block',
  templateUrl: './cs-block.component.html',
  styleUrls: ['./cs-block.component.scss']
})
export class CsBlockComponent {

  @Input() data: CSBlock;
  @Input() times: string[];

  @Input() xmlTheme: Document;
  @Input() expand: boolean;

}
