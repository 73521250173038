import * as Excel from 'exceljs';

import { CSConstants } from '../csconstants.enum';
import { CSLineItem } from './cslineitem.model';
import { CSPart } from './cspart.model';

export class CSBlock {

  public title: CSPart;
  public subtitle: CSPart;

  public items: CSLineItem[] = [];

  public constructor(inItems: Excel.Row[]) {

    let curItem: Excel.Row[] = [];

    inItems.forEach((curRow: Excel.Row) => {
      const curCell: Excel.Cell = curRow.getCell(CSConstants.BLOCK_TITLE_COL);
      if (curCell && curCell.value) {
        if (!this.title) {
          this.title = new CSPart(curCell);
        } else if (!this.subtitle) {
          this.subtitle = new CSPart(curCell);
        }
      }

      if (curItem.length > 0 && curRow.getCell(CSConstants.ITEM_TITLE_COL)) {
        this.items.push(new CSLineItem(curItem));
        curItem = [];
      }

      curItem.push(curRow);
    });

    if (curItem.length > 0) {
      this.items.push(new CSLineItem(curItem));
    }
  }

}
