import * as ExcelProper from 'exceljs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { datadogRum } from '@datadog/browser-rum';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoverSheet } from '../models/coversheet/coversheet.model';
import { CSBlock } from '../models/coversheet/csblock.model';
import { CoversheetPackage } from '../models/coversheetpackage.model';
import { CSConstants } from '../models/csconstants.enum';
import { CSStatus } from '../models/csstatus.enum';
import { DisplayMode } from '../models/displayMode.enum';
import { Location } from '../models/location.enum';
import { WebsocketService } from './websocket.service';
import { environment } from '../../../environments/environment';

declare const ExcelJS: any; // Needed for production use

dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root',
})
export class CoversheetService {
  public coversheet: Subject<CoversheetPackage | void> =
    new Subject<CoversheetPackage | void>();
  private locCD: Location;

  constructor(private websocket: WebsocketService, private http: HttpClient) {
    // receives coversheet notification and gets latest coversheet
    websocket
      .getSubject<void>('coversheet')
      .pipe(switchMap(() => this.getCurrentCoverSheet(this.locCD)))
      .subscribe((coversheet: CoversheetPackage) => {
        console.log(coversheet);
        this.coversheet.next(coversheet);
      });

    websocket.getSubject<void>('socketReconnected').subscribe(() => {
      if (this.locCD) {
        this.setCoversheetLocation(this.locCD);
        this.coversheet.next();
      }
    });
  }

  public setCoversheetLocation(locCD: Location): void {
    console.log('setting location:: ' + locCD);
    this.locCD = locCD;
    this.websocket.sendRequest('setLocation', { locCD });

    this.getCurrentCoverSheet(this.locCD).subscribe(
      (coversheet: CoversheetPackage) => {
        this.coversheet.next(coversheet);
      }
    );
  }

  public getCurrentCoverSheet(locCD: Location): Observable<CoversheetPackage> {
    return this.http
      .get<CoversheetPackage>(`${environment.httpApiUrl}/${locCD}`)
      .pipe(
        catchError((err) => {
          datadogRum.addError(err);
          return EMPTY;
        })
      );
  }

  public updateCoverSheet(coversheet: CoversheetPackage): Observable<void> {
    return this.http
      .post<void>(`${environment.httpApiUrl}/${this.locCD}`, coversheet)
      .pipe(
        catchError((err) => {
          datadogRum.addError(err);
          return EMPTY;
        })
      );
  }

  public async parseCoversheetFile(
    locCD: Location,
    file: File
  ): Promise<CoversheetPackage> {
    const workbook: ExcelProper.Workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(await new Response(file).arrayBuffer());
    const themes:string = (workbook as any)._themes.theme1
    const xmlString = themes;
    return {
      coversheet: this.parseCoverSheet(workbook),
      xmlTheme: xmlString,
      timestamp: dayjs(),
      locCD,
      mode: DisplayMode.NORMAL,
      expiresAt: dayjs().add(3, 'd').unix(),
      status: CSStatus.DRAFT,
    } as CoversheetPackage;
  }

  private parseCoverSheet(workbook: ExcelProper.Workbook): CoverSheet | any {
    if (!workbook) {
      throw new Error('Workbook is null.');
    }

    const date: dayjs.Dayjs = dayjs().tz('America/New_York');
    const dateMD: string = date.format('M-D');
    const worksheet: ExcelProper.Worksheet = workbook.getWorksheet(dateMD);

    if (!worksheet) {
      throw new Error(`No sheet for date ${dateMD}.`);
    }

    if (worksheet.rowCount === 0 || worksheet.columnCount === 0) {
      throw new Error(`Worksheet contains no data for date ${dateMD}.`);
    }

    const ret = new CoverSheet(date);
    let curBlock: ExcelProper.Row[] = [];

    ret.times = this.getShowTimes(worksheet.getRow(1)); // get show times from the first row

    worksheet.eachRow((curRow) => {
      if (curRow.number === 1) {
        return;
      }

      let checkCell: ExcelProper.Cell = null;

      for (let i = 1; !checkCell && i <= CSConstants.MAX_ELEMENT_COL; i++) {
        checkCell = curRow.getCell(i);
      }

      if (!checkCell) {
        return;
      }

      const styleCheck: Partial<ExcelProper.Style> = checkCell.style;

      if (styleCheck && styleCheck.border.top && curBlock.length > 0) {
        ret.blocks.push(new CSBlock(curBlock));
        curBlock = [];
      }

      if (curRow.hasValues) {
        curBlock.push(curRow);
      }

      if (styleCheck && styleCheck.border.bottom) {
        ret.blocks.push(new CSBlock(curBlock));
        curBlock = [];
      }
    });

    return ret;
  }

  /**
   * This method parses show times from a row in an Excel sheet.
   * It will filter out the blank cells and only return cells containing a time.
   * @param row The row to retrieve show times from
   */
  private getShowTimes(row: ExcelProper.Row): string[] | any {
    const times: string[] = [];

    row.eachCell((cell) => {
      times.push(
        dayjs(cell.value as Date)
          .tz('GMT')
          .format('h:mm A')
      );
    });

    return times;
  }
}
