import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { CsBlockComponent } from '../coversheet/cs-block/cs-block.component';
import { CsElementComponent } from './cs-element/cs-element.component';
import { CsLineItemComponent } from './cs-line-item/cs-line-item.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from './material/material.module';
import { RouterLinkStubDirective } from './tests/routerlinkstub.directive';
import { TimesRowComponent } from './times-row/times-row.component';
import { ExcelStyle } from './common/excel-style';

const shareMe: any[] = [
  HeaderComponent,
  FooterComponent,
  RouterLinkStubDirective,
  TimesRowComponent,
  CsBlockComponent,
  CsLineItemComponent,
  CsElementComponent,
];

@NgModule({
  declarations: shareMe,
  exports: [MaterialModule, ExcelStyle, shareMe], imports: [CommonModule, MaterialModule, ExcelStyle], providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class SharedModule { }
