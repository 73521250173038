<div class="expand">
  <button (click)="expandToggle()">{{!expand ? 'Expand' : 'Collapse'}} All</button>
</div>
<csw-times-row class="header-time-row" *ngIf="!page" [times]="coversheet?.coversheet.times"></csw-times-row>
<mat-accordion multi="true" *ngIf="!page">
  <csw-block [expand]="expand" *ngFor="let curBlock of coversheet?.coversheet.blocks" [data]="curBlock" [xmlTheme]="xmlTheme" [times]="coversheet.coversheet.times"></csw-block>
</mat-accordion>
<ng-container *ngIf="page">
  <csw-block-paged *ngFor="let curBlock of coversheet?.coversheet.blocks" [data]="curBlock" [xmlTheme]="xmlTheme" [times]="coversheet.coversheet.times" [displayMode]="coversheet.mode" [location]="location"></csw-block-paged>
</ng-container>
