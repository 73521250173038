import { Component, OnInit } from '@angular/core';

import { Location } from '../shared/models/location.enum';

@Component({
  selector: 'csw-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  locations: string[] = Object.keys(Location);
  locEnum: typeof Location = Location;

  constructor() { }

  ngOnInit() {
  }

}
