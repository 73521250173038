import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {

  private ws:WebSocket;
  private wsTimeout = 10000;
  private observables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  private pendingRequests: any[] = [];

  constructor() {
    this.connectWebsocket();
  }

  ngOnDestroy() {
    this.ws.close();
  }

  private retryConnection():void {
    setTimeout(() => {
      console.log('attempting to reconnect');
      this.connectWebsocket();
      this.getSubject<void>('socketReconnected').next();
    }, this.wsTimeout);
  }

  private connectWebsocket() {
    console.log('running connectWebsocket()');
    this.ws = new WebSocket(environment.websocketApiUrl);
    this.ws.onerror = (error) => {
      console.error(`Websocket error:` + JSON.stringify(error));
      this.ws.close()
      throw new Error(`Websocket error: ${error}`)
    };

    this.ws.onopen = () => {
      console.log('opened')
      if (this.ws.readyState !== WebSocket.CONNECTING) {
         this.pendingRequests.forEach(curReq => this.ws.send(curReq));
         this.pendingRequests = [];
      //browser does not support ping control frame, so calling API gateway knowing we will get 403 but this will make the browser detect
      //if firewall or upstream issue has occured and to try to reconnect to server
      setInterval(() =>  this.ws.send('ping'), 61000)
       }
    };

    this.ws.onmessage = (msg) => {

      const data = JSON.parse(msg.data);
      console.log('got message' + JSON.stringify(data))
      const subj: Subject<any> = this.observables.get(data.action);
      if (subj) {
        subj.next(data.data);
      }
    };

    this.ws.onclose = e => {
      console.log('onClose event'+ e);
      this.retryConnection()

    };
  }



  public sendRequest(action: string, data?: any) {

    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify({ action, data }));
    } else {
      this.pendingRequests.push(JSON.stringify({ action, data }));
      if (this.ws.readyState !== WebSocket.CONNECTING) {
        // Reconnect
        this.connectWebsocket();
      }
    }
  }

  public getSubject<T>(action: string): Subject<T> {
    let subj: Subject<T> = this.observables.get(action);
    if (!subj) {
      subj = new Subject<T>();
      this.observables.set(action, subj);
    }
    return subj;
  }

}
