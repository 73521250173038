import { CSFont } from './csfont.model';

export class CSText {
  text: string;
  font?: CSFont;

  constructor(text: string, font?: CSFont) {
    this.text = text;
    this.font = font;
  }
}
