import * as Excel from 'exceljs';

import { CSFill } from './csfill.model';
import { CSFont } from './csfont.model';
import { CSText } from './cstext.model';

export class CSPart {
  public text: CSText[] = [];
  public blank = true;
  public font: CSFont;
  public fill: CSFill;
  public constructor(partCell?: Excel.Cell) {
    if (partCell && partCell.value) {
      const richText = (partCell.value as Excel.CellRichTextValue).richText;

      if (richText) {
        richText.forEach((textValue) =>
          this.text.push(new CSText(textValue.text, textValue.font as CSFont))
        );
      }
      this.font = partCell.font as CSFont;
      this.fill = partCell.fill as CSFill;
      if (this.text.length === 0) {
        this.text = [new CSText(partCell.text)];
      }
      this.blank = !this.text;
    }
  }
}
