import { instrumentMethod } from '../tools/instrumentMethod';
import { callMonitored, monitor } from '../tools/monitor';
import { Observable } from '../tools/observable';
import { clocksNow } from '../tools/utils/timeUtils';
import { normalizeUrl } from '../tools/utils/urlPolyfill';
var fetchObservable;
export function initFetchObservable() {
  if (!fetchObservable) {
    fetchObservable = createFetchObservable();
  }
  return fetchObservable;
}
function createFetchObservable() {
  var observable = new Observable(function () {
    if (!window.fetch) {
      return;
    }
    var stop = instrumentMethod(window, 'fetch', function (originalFetch) {
      return function (input, init) {
        var responsePromise;
        var context = callMonitored(beforeSend, null, [observable, input, init]);
        if (context) {
          responsePromise = originalFetch.call(this, context.input, context.init);
          callMonitored(afterSend, null, [observable, responsePromise, context]);
        } else {
          responsePromise = originalFetch.call(this, input, init);
        }
        return responsePromise;
      };
    }).stop;
    return stop;
  });
  return observable;
}
function beforeSend(observable, input, init) {
  var method = init && init.method || input instanceof Request && input.method || 'GET';
  var url = input instanceof Request ? input.url : normalizeUrl(String(input));
  var startClocks = clocksNow();
  var context = {
    state: 'start',
    init: init,
    input: input,
    method: method,
    startClocks: startClocks,
    url: url
  };
  observable.notify(context);
  return context;
}
function afterSend(observable, responsePromise, startContext) {
  var reportFetch = function (response) {
    var context = startContext;
    context.state = 'resolve';
    if ('stack' in response || response instanceof Error) {
      context.status = 0;
      context.isAborted = response instanceof DOMException && response.code === DOMException.ABORT_ERR;
      context.error = response;
    } else if ('status' in response) {
      context.response = response;
      context.responseType = response.type;
      context.status = response.status;
      context.isAborted = false;
    }
    observable.notify(context);
  };
  responsePromise.then(monitor(reportFetch), monitor(reportFetch));
}
