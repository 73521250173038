<ng-container *ngIf="isAdmin">
  <div id="buttonHolder" (mouseleave)="showMenu(false)">
    <button matTooltop="test" mat-fab class="absPosition" (dragenter)="fileHover($event)" (mouseenter)="showMenu(true)"><mat-icon>edit</mat-icon></button>
    <div id="menu" *ngIf="isMenu" [@menuAnimation]>
      <button mat-mini-fab class="menuButton" (click)="publishCoversheet()" matTooltip="Publish Coversheet"><mat-icon>open_in_browser</mat-icon></button>
      <button mat-mini-fab color="warn" class="menuButton" (click)="publishCoversheet(true)" matTooltip="Publish in Chaos Mode"><mat-icon>aspect_ratio</mat-icon></button>
      <!-- <button mat-mini-fab class="menuButton" matTooltip="Load last coversheet (doesn't publish)"><mat-icon>undo</mat-icon></button> -->
    </div>
  </div>
  <div id="dropTarget" class="absPosition" *ngIf="dropReady" (dragleave)="fileHover()" (drop)="parseCoversheet($event)" (dragover)="prepDrop($event)" [@dragAnimation]>
    <h3>Drop Coversheet Excel File Here</h3>
  </div>
</ng-container>
