<p
  *ngFor="let curPart of parts"
  [ngStyle]="curPart.font | excelStyle : curPart.fill : xmlTheme"
>
  <span
    *ngFor="let curChunk of curPart.text"
    [ngStyle]="curChunk.font | excelStyle : curChunk.fill : xmlTheme"
    >{{ curChunk.text }}</span
  >
</p>
