import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';

const matModules = [
  MatButtonToggleModule,
  MatIconModule,
    MatExpansionModule,
    MatButtonModule,
    MatCardModule,
  ReactiveFormsModule,
  MatProgressBarModule,
  MatSnackBarModule

];

@NgModule({
  imports: [
    CommonModule,
    matModules
  ],
  exports: [
    matModules
  ],
  declarations: []
})
export class MaterialModule { }
