import {
    AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren, HostListener
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CoversheetPackage } from '../shared/models/coversheetpackage.model';
import { DisplayMode } from '../shared/models/displayMode.enum';
import { Location } from '../shared/models/location.enum';
import { CsBlockPagedComponent } from './cs-block-paged/cs-block-paged.component';

@Component({
  selector: 'csw-coversheet',
  templateUrl: './coversheet.component.html',
  styleUrls: ['./coversheet.component.scss']
})
export class CoversheetComponent implements OnInit, AfterViewInit {

  @Input()
  coversheet: CoversheetPackage;
  @ViewChildren(CsBlockPagedComponent)
  blocks: QueryList<CsBlockPagedComponent>;
  @Input()
  location: Location;
  @Input() xmlTheme: Document;
  page: number;
  expand: boolean = false;

  constructor(private route: ActivatedRoute, private changeDet: ChangeDetectorRef) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.page = params['p'];
    });
  }

  ngAfterViewInit() {
    if (window && this.page) {
      this.assignPages(window.innerHeight);

      this.blocks.changes.subscribe(() => {
        this.assignPages(window.innerHeight);
      });
    }
  }

  @HostListener('window:resize')
  onResize() {
    if (window && this.page) {
      // reload the page to resize all child components appropriately
      window.location.reload();
    }
  }

  private assignPages(pageHeight: number) {
    const headerHeight = 50;
    let curHeightUsed = 0;
    let curPage = 1;
    this.blocks.forEach(curBlock => {
      const eleHeight = curBlock.height + 40;
      if (this.coversheet.mode === DisplayMode.CHAOS) {
        if (curHeightUsed + eleHeight > pageHeight - 100) {
          curPage++;
          curHeightUsed = headerHeight + eleHeight;
        } else if (curHeightUsed + eleHeight > pageHeight + 25) {
          // continue on 2 screens
          const overlap = pageHeight - curHeightUsed;
          curBlock.setBlockOverlap(overlap);
          curBlock.addPageOn(curPage);

          // set overlap
          curPage++;
          curHeightUsed = eleHeight - overlap + headerHeight;
        } else {
          curHeightUsed += eleHeight;
        }
      } else {
        if (curHeightUsed + eleHeight > pageHeight - 180) {
          curPage++;
          curHeightUsed = headerHeight;
        }
        curHeightUsed += eleHeight;
      }
      curBlock.addPageOn(curPage);
    });
    this.changeDet.detectChanges();
  }

  expandToggle() {
    this.expand = !this.expand;
  }
}
