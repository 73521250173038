import { DisplayMode } from 'src/app/shared/models/displayMode.enum';
import { LineDisplay } from 'src/app/shared/models/lineDisplay.enum';

import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CsBlockComponent } from '../cs-block/cs-block.component';
import { Location } from 'src/app/shared/models/location.enum';

@Component({
  selector: 'csw-block-paged',
  templateUrl: './cs-block-paged.component.html',
  styleUrls: ['./cs-block-paged.component.scss']
})
export class CsBlockPagedComponent extends CsBlockComponent {

  displays = LineDisplay;
  modes = DisplayMode;

  @HostBinding('style.display')
  display: string;
  @HostBinding('style.margin-top')
  topMargin: string;
  @Input()
  displayMode: DisplayMode;
  @Input()
  location: Location;
  @Input() xmlTheme: Document;

  private curPage: number;
  private pagesOn: number[] = [];
  private overlap: number;

  constructor(private route: ActivatedRoute, private elRef: ElementRef) {
    super();

    this.route.queryParams.subscribe(params => {
      this.curPage = +params['p'];
      this.checkVisibility();
    });
  }

  get height(): number {
    return this.elRef.nativeElement.scrollHeight;
  }

  public setBlockOverlap(overlap: number) {
    this.overlap = overlap;
    this.checkVisibility();
  }

  public addPageOn(onPage: number) {
    this.pagesOn.push(onPage);
    this.checkVisibility();
  }

  private checkVisibility() {
    if (this.curPage && this.pagesOn && this.pagesOn.length) {
      this.display = this.pagesOn.includes(this.curPage) ? undefined : 'none';
      if (this.overlap && this.pagesOn[0] !== this.curPage) {
        this.topMargin = -this.overlap + 'px';
      } else {
        this.topMargin = null;
      }
    } else {
      this.display = undefined;
    }
  }

}
