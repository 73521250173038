import { CSFont } from '../models/coversheet/csfont.model';
import { CSFill } from '../models/coversheet/csfill.model';
import { CSStyle } from '../models/coversheet/csstyle.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'excelStyle',
})
export class ExcelStyle implements PipeTransform {

  /**
   * Converts Excel font/fill properties into a CSS style format
   *
   * @param font the excel-native font information
   * @param fill the excel-native fill information
   */
  transform(font?: CSFont, fill?: CSFill, xmlTheme?: Document): CSStyle {
    const ret: CSStyle = new CSStyle();

    if (font) {
      ret['font-weight'] = font.bold ? 'bold' : 'normal';
      if (font.strike) {
        ret['text-decoration'] = 'line-through';
        ret['text-decoration-thickness'] = '.15rem';
      }

      if (font.color && xmlTheme) {
        const jsonTheme = font.color['theme'];
        const jsonTint = font.color['tint'];
        const accentIndex = jsonTheme - 3;
        //https://github.com/exceljs/exceljs/issues/1690
        if (accentIndex && jsonTint && jsonTheme) {
          const accentColorElement = xmlTheme.querySelector(
            `accent${accentIndex}`
          );
          const accentColor = accentColorElement
            ? accentColorElement.querySelector('srgbClr').getAttribute('val')
            : null;
          if (accentColor !== '#000000') {
            ret.color = this.shadeColor(accentColor, jsonTint) ?? '#e9e6d5';
          }
        }
      }

      if (font.color && font.color.argb) {
        const textColor = this.getColor(font.color.argb);
        if (textColor) {
          ret.color = textColor;
        }
      }
    }

    if (fill && fill.fgColor && fill.fgColor.argb) {
      ret['background-color'] = this.getColor(fill.fgColor.argb);
    }

    return ret;
  }

  /**
   * Converts Excel color values to CSS colors
   * https://github.com/exceljs/exceljs/issues/1514#issuecomment-1336123679
   *
   * @param excelARGB the excel-native ARGB color value
   */
  getColor(excelARGB: string): string {
    if (excelARGB.length < 8) {
      return '';
    }
    const argb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(excelARGB);
    const hex = {
      r: parseInt(argb[2], 16),
      g: parseInt(argb[3], 16),
      b: parseInt(argb[4], 16),
      a: parseInt(argb[1], 16) / 255,
    }
    return "#" + ((1 << 24) + (hex.r << 16) + (hex.g << 8) + hex.b)
    	.toString(16)
    	.slice(1);
  }

  /**
   * Generates a shaded color based on the provided hexadecimal color and tint value.
   * https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
   * The function follows a formula to programmatically lighten or darken the color.
   * @param col - The hexadecimal color code (e.g., "#RRGGBB").
   * @param tint - Tint value, where positive values lighten the color and negative values darken it.
   * @returns The shaded color as a hexadecimal string or undefined if inputs are invalid.
   */
  private shadeColor(col: string, tint: number): string | undefined {
    if (!col || !tint) return undefined;
    // Convert hex to decimal
    const amountTint = tint * 100;
    const hexValue = parseInt(col, 16);
    // Extract the red, green and blue parts
    let r = (hexValue >> 16) + amountTint;
    let b = ((hexValue >> 8) & 0x00ff) + amountTint;
    let g = (hexValue & 0x0000ff) + amountTint;
    // Cut the values to 0-255 range
    r = Math.max(0, Math.min(255, r));
    b = Math.max(0, Math.min(255, b));
    g = Math.max(0, Math.min(255, g));
    // Convert back to hex
    return '#' + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
  }
}
