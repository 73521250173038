
<div class="header">
  <div id="clock">
    <h3>{{curTime | date: 'EEEE, MMMM d'}}</h3>
    <h3>{{curTime | date: 'h:mm:ss a'}}</h3>
  </div>
  <div id="logo" *ngIf="logoUrl && _displayMode === displays.NORMAL">
    <img [src]="logoUrl"/>
  </div>
  <div id="lastUpdated" *ngIf="lastUpdated">
    <h3>Last Updated &nbsp;</h3>
    <h3>{{lastUpdated | date: 'M/d h:mm:ss a'}}</h3>
  </div>
</div>

<div class="header-mobile">
  
  <div *ngIf="logoUrl && _displayMode === displays.NORMAL">
    <img [src]="logoUrl"/>
  </div>
  <div id="clock">
    <h3>{{curTime | date: 'EEEE, MMMM d'}}</h3>
    <h3>{{curTime | date: 'h:mm:ss a'}}</h3>
  </div>

  <div id="lastUpdated" *ngIf="lastUpdated">
    <h3>Last Updated &nbsp;</h3>
    <h3>{{lastUpdated | date: 'M/d h:mm:ss a'}}</h3>
  </div>

</div>

