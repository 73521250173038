import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'csw-times-row',
  templateUrl: './times-row.component.html',
  styleUrls: ['./times-row.component.scss']
})
export class TimesRowComponent {

  @Input()
  times: string[];

  @HostBinding('class.coversheetRowTemplate')
  csRowTemplate = true;

}
