import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { datadogRum } from '@datadog/browser-rum';

import config from './config.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

datadogRum.init({
  applicationId: '88b32b2f-a486-4444-a1b9-6dff4511b8fb',
  clientToken: 'pubb8afd3d69a416a2bab8dd8a8e41d708b',
  site: 'datadoghq.com',
  service: 'coversheet',
  env: environment.tag,
  version: config ? (config as any).version : null,
  sampleRate: 100,
  replaySampleRate: 0,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  trackSessionAcrossSubdomains: true,
  useSecureSessionCookie: true,
  allowedTracingOrigins: [environment.httpApiUrl, environment.websocketApiUrl],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
