<mat-card *ngIf="data">
  <csw-times-row [times]="times" *ngIf="displayMode !== modes.CHAOS"></csw-times-row>
  <mat-card-content>
    <div id="title">
      <h2 [ngStyle]="data.title?.font | excelStyle:data.title?.fill:xmlTheme">
          <span *ngFor="let curChunk of data.title?.text" [ngStyle]="curChunk.font | excelStyle:curChunk.fill:xmlTheme">
            {{curChunk.text}}
          </span>
        </h2>
        <p [ngStyle]="data.subtitle?.font | excelStyle:data.subtitle?.fill:xmlTheme">
          <span *ngFor="let curChunk of data.subtitle?.text" [ngStyle]="curChunk.font | excelStyle:curChunk.fill:xmlTheme" >{{curChunk.text}}</span>
        </p>
    </div>
    <csw-line-item *ngFor="let curItem of data.items" [item]="curItem" [lineDisplay]="displayMode === modes.CHAOS ? displays.CHAOS : displays.PAGED" [location]="location"></csw-line-item>
  </mat-card-content>
</mat-card>
