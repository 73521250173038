import { animate, group, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';

import { CoversheetPackage } from '../shared/models/coversheetpackage.model';
import { DisplayMode } from '../shared/models/displayMode.enum';
import { Location } from '../shared/models/location.enum';
import { CoversheetService } from '../shared/services/coversheet.service';


@Component({
  selector: 'csw-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger(
      'dragAnimation', [
        transition(':enter', [
          style({ width: '1em', height: '1em' }),
          group([
            animate('250ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ width: '20vw', height: '30vh' })),
            query('h3', [
              style({ opacity: 0}),
              animate('300ms ease-out', style({ opacity: 1 }))
            ])
          ])
        ])
      ]
    ),
    trigger(
      'menuAnimation', [
        transition(':enter', [
          query('button', [
            style({ opacity: 0, transform: 'scale(.75, .75) translateX(1em)' }),
            stagger(-65, [
              animate('200ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1, transform: 'scale(1, 1) translateX(0)' }))
            ])
          ])
        ]),
        transition(':leave', [
          query('button', [
            stagger(25, [
              animate('150ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'scale(.5, .5) translateX(1em)' }))
            ])
          ])
        ])
      ]
    )
  ]
})
export class AdminComponent implements OnInit {

  isAdmin = false;
  dropReady = false;
  isMenu = false;

  @Input()
  locCD: Location;
  @Output()
  private csLoaded: EventEmitter<CoversheetPackage> = new EventEmitter<CoversheetPackage>();
  @Output()
  csPublish: EventEmitter<DisplayMode> = new EventEmitter<DisplayMode>();

  constructor(private route: ActivatedRoute, private coversheetService: CoversheetService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.isAdmin = params['admin'];
    });
  }

  fileHover(event) {
    if (!event) {
      this.dropReady = false;
    } else {
      this.dropReady = true;
    }
  }

  showMenu(show: boolean) {
    this.isMenu = show;
  }

  prepDrop(event: DragEvent) {
    this.preventStopDefault(event);
    event.dataTransfer.dropEffect = 'copy';
  }

  parseCoversheet(event: DragEvent) {
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
      const coversheetFiles: DataTransferItemList = event.dataTransfer.items;
      if (coversheetFiles.length > 0 && coversheetFiles[0].kind === 'file') {
        console.log('parsing coversheet...');
        this.csLoaded.emit(undefined);
        this.coversheetService.parseCoversheetFile(this.locCD, coversheetFiles[0].getAsFile()).then(coversheet => {
          this.snackBar.open('Loaded', null, { duration: 3000 });
          this.csLoaded.emit(coversheet);
        }).catch(error => {
          this.csLoaded.emit(null);
          datadogRum.addError(error);
          this.snackBar.open(error, null, { duration: 3000 });
        });
      }

      this.preventStopDefault(event);
      this.dropReady = false;
    }
  }

  publishCoversheet(chaos?: boolean) {
    this.csPublish.emit(chaos ? DisplayMode.CHAOS : DisplayMode.NORMAL);
    this.snackBar.open('Published', null, { duration: 3000 });
  }

  private preventStopDefault(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

}
