import dayjs from 'dayjs';

import { CSStatus } from '../csstatus.enum';
import { DisplayMode } from '../displayMode.enum';
import { Location } from '../location.enum';
import { CSBlock } from './csblock.model';

export class CoverSheet {
  public blocks: CSBlock[] = [];
  public times: string[] = [];

  public lastUpdated: string;
  public coverSheetDate: string;
  public locCD: Location;
  public mode: DisplayMode;
  public status: CSStatus;
  public expiresAt: number;

  public PK: string;
  public SK: string;

  public constructor(date: dayjs.Dayjs) {
    this.coverSheetDate = date.format('dddd, MMMM d');
  }
}
