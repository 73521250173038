import { operate } from '../util/lift';
import { OperatorSubscriber } from './OperatorSubscriber';
export function map(project, thisArg) {
  return operate((source, subscriber) => {
    let index = 0;
    source.subscribe(new OperatorSubscriber(subscriber, value => {
      subscriber.next(project.call(thisArg, value, index++));
    }));
  });
}
