import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './admin/admin.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoversheetComponent } from './coversheet/coversheet.component';
import { CsBlockPagedComponent } from './coversheet/cs-block-paged/cs-block-paged.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SharedModule } from './shared/shared.module';
import { WallboardComponent } from './wallboard/wallboard.component';

@NgModule({
  declarations: [
    AppComponent,
    WallboardComponent,
    CoversheetComponent,
    HomepageComponent,
    AdminComponent,
    CsBlockPagedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
