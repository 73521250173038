import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShowImage } from './directus.models';

dayjs.extend(isBetween);

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  constructor(private http: HttpClient) {}

  getHeaderLogoUrl(locationCode: string) {
    const params = new HttpParams()
      .set('fields', 'ticketCenterShowImage,startDate,endDate')
      .set('filter[location][locationCode][_eq]', locationCode === 'LANCASTER_PA' ? 'STRASBURG_PA' : locationCode);
    return this.http
      .get<{ data: ShowImage[] }>(`${environment.cmsUrl}shows`, {
        params,
      })
      .pipe(
        map((page) => page.data.filter((show) => dayjs().isBetween(dayjs(show.startDate), dayjs(show.endDate)))),
        map((shows) => shows[0].ticketCenterShowImage),
        map((showImage) => 'https://sst-prod.cdn.sight-sound.com/assets/' + showImage),
      );
  }
}
