import { Subject } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

import { CoversheetPackage } from '../shared/models/coversheetpackage.model';
import { CSStatus } from '../shared/models/csstatus.enum';
import { DisplayMode } from '../shared/models/displayMode.enum';
import { Location } from '../shared/models/location.enum';
import { CoversheetService } from '../shared/services/coversheet.service';

@Component({
  selector: 'csw-wallboard',
  templateUrl: './wallboard.component.html',
  styleUrls: ['./wallboard.component.scss']
})
export class WallboardComponent implements OnInit, OnDestroy {

  public curLoc: Location;
  public coversheet: CoversheetPackage;

  public xmlTheme: Document;
  private navAway: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute, private csService: CoversheetService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.curLoc = params['locCD'];

      this.csService.setCoversheetLocation(this.curLoc);
      this.csService.coversheet.subscribe(curCoversheet => {
        this.coversheet = curCoversheet as CoversheetPackage;
        console.log('Coversheet updated');
        if(this.coversheet?.xmlTheme){
        this.parseXml();
        }
        this.snackBar.open('Coversheet updated.', null, { duration: 3000 });
      });
      this.csService.getCurrentCoverSheet(this.curLoc);
    });
  }

  ngOnDestroy() {
    this.navAway.next(null);
    this.navAway.complete();
  }

  updateCoversheet() {
    this.csService.getCurrentCoverSheet(this.curLoc);
  }

  coversheetLoaded(coversheet: CoversheetPackage) {
    console.log('loaded');
    this.coversheet = coversheet;
    if(coversheet?.xmlTheme){
      this.parseXml();
    }

  }

  publishCoversheet(mode: DisplayMode) {
    console.log('publishing...');
    this.coversheet.mode = mode;
    this.coversheet.status = CSStatus.PUBLISHED;
    this.csService.updateCoverSheet(this.coversheet).subscribe();
  }

  private parseXml(): void {
    const parser = new DOMParser();
    this.xmlTheme = parser.parseFromString(this.coversheet.xmlTheme.replace(/a:/g, ''), 'text/xml');
  }
}
