var browserIsIE;
export function isIE() {
  return browserIsIE !== null && browserIsIE !== void 0 ? browserIsIE : browserIsIE = Boolean(document.documentMode);
}
var browserIsChromium;
export function isChromium() {
  return browserIsChromium !== null && browserIsChromium !== void 0 ? browserIsChromium : browserIsChromium = !!window.chrome || /HeadlessChrome/.test(window.navigator.userAgent);
}
var browserIsSafari;
export function isSafari() {
  return browserIsSafari !== null && browserIsSafari !== void 0 ? browserIsSafari : browserIsSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
